import React from "react"

// Libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

const ComingSoon = () => {
  const navigationLinks = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Vision",
      url: "https://ndb.city",
    },
    {
      label: "Technology",
      url: "/technology",
    },
    {
      label: "Learn",
      url: "/learn",
    },
    {
      label: "Contact Us",
      url: "/#contactUs",
    },
  ]

  const { file: image } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "coming-soon/coming-soon-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 478, quality: 100)
        }
      }
    }
  `)

  return (
    <Layout footerLess>
      <SEO title="Auction | Coming Soon | NDB" />
      <section className="coming-soon section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-8 col-md-6 col-lg-6">
              <h1 className="highlight mb-4">
                auction will be starting soon!{" "}
              </h1>
              <h3 className="subtitle">
                <a href="https://share.hsforms.com/1uASSxWOlQsWUX3inkpWpTQ4jiis">
                  JOIN
                </a>{" "}
                OUR WAITING LIST
              </h3>
            </div>

            <div className="image-wrapper col-12 col-sm-4 col-md-3">
              <div className="image">
                <GatsbyImage image={getImage(image)} alt="" />
              </div>
              <ul className="d-flex flex-column d-none d-md-block">
                {navigationLinks.map(link => (
                  <li>
                    <Link to={link.url} className="font-weight--700">
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ComingSoon
